.footerSection {
    --max-section-width: 78.75rem;
    --heart-red: rgb(230, 79, 62);
    --margin-top: var(--ds-spacing-super);
    --address-max-width: 18rem;
    --address-flex-direction: column;
    --address-margin-left: none;
    --address-padding: var(--ds-spacing-micro) 0 var(--ds-spacing-large) 0;
    --ds-logo-width: 17.75rem;
    --ds-logo-height: 2.37rem;

    @media (--ds-mq-large) {
        --address-flex-direction: row;
        --address-margin-left: 7.5rem;
        --address-padding: var(--ds-spacing-generous) 0 var(--ds-spacing-large) 0;
    }

    width: 100%;
    max-width: var(--max-section-width);
    margin: var(--margin-top) auto 0 auto;
}

.dsLogo {
    width: var(--ds-logo-width);
    height: var(--ds-logo-height);
}

.bodyWrapper {
    display: flex;
    flex-direction: var(--address-flex-direction);
}

.body {
    color: var(--ds-colour-secondary);
    padding: var(--ds-spacing-generous) 0;
}

.heart {
    color: var(--heart-red);
}

.address {
    max-width: var(--address-max-width);
    margin-left: var(--address-margin-left);
    padding: var(--address-padding);
    color: var(--ds-colour-secondary);
}

.addressTitle {
    font-size: var(--ds-font-size-tiny);
    font-weight: var(--ds-font-weight-bold);
    line-height: var(--ds-line-height-small);
    margin-bottom: var(--ds-spacing-micro);
}

.links {
    text-transform: uppercase;

    & > a {
        margin-bottom: var(--ds-spacing-generous);
    }
}

.copyright {
    color: var(--ds-colour-secondary);
}
